<template>
    <div>
        <ts-page-title
            :title="$t('requestDelivery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('requestDelivery.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="sm:tw-flex  tw-space-x-2">
                        <a-button type="primary" @click="fetchData">
                            {{ $t("refresh") }}
                        </a-button>
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="true"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                        <a-select
                            v-model="status"
                            style="width: 220px"
                            :options="statusOpt"
                            showSearch
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                        <a-select
                            v-model="service_type_id"
                            style="width: 220px"
                            :options="serviceType"
                            showSearch
                            mode="multiple"
                            placeholder="All"
                            :max-tag-count="1"
                            :max-tag-text-length="7"
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                        <a-select
                            v-if="$whois.admin()"
                            v-model="agency_id"
                            style="width: 220px"
                            :options="agencies"
                            showSearch
                            mode="multiple"
                            placeholder="All Agency"
                            :max-tag-count="1"
                            :max-tag-text-length="7"
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                    </div>
                    <div class="tw-space-x-3 tw-flex tw-items-center">
                        <ts-checkbox
                            v-model="isStockAtJd"
                            @change="fetchData()"
                            >{{ $t("requestDelivery.stockAtJD") }}</ts-checkbox
                        >
                        <a-tooltip :title="$t('requestDelivery.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('requestDelivery.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td class="tw-whitespace-nowrap">
                            {{ record.booking_time }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.transaction_date }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_start_time }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_finish_time }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.booking_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.shop_code }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.shop_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.agency_name }}
                        </td>
                        <td class="tw-whitespace-nowrap tw-text-center">
                            {{ record.pickup_fee }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.driver_pickup }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.driver_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.t_driver_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.service_type_name_kh }}
                        </td>
                        <td class="tw-text-center">
                            {{ record.number_of_package }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.package_type_kh }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            <ts-accounting-format
                                :digit="2"
                                :value="record.amount_collected_usd"
                            ></ts-accounting-format>
                        </td>
                        <td class="tw-whitespace-nowrap">
                            <ts-accounting-format
                                :digit="0"
                                currency="KHR"
                                :value="record.amount_collected_khr"
                            ></ts-accounting-format>
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.receiver_phone }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.receiver_location }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_status }}
                        </td>
                        <td class="tw-text-center">
                            <span
                                class="badge bg-primary"
                                v-if="record.is_stock_at_jd"
                                >Yes</span
                            >
                            <span class="badge bg-secondary" v-else>No</span>
                        </td>
                        <td class="tw-text-center">
                            <span
                                class="badge bg-primary"
                                v-if="record.is_fee_charge"
                                >Yes</span
                            >
                            <span class="badge bg-secondary" v-else>No</span>
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.delivery_status_remark }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.created_by_name }}
                        </td>
                        <td class="text-left tw-space-x-3">
                            <a
                                :href="record.bank_transfer_slip"
                                target="_blank"
                                v-if="record.bank_transfer_slip"
                            >
                                <i class="far fa-file-image"></i>
                            </a>
                            <a
                                v-if="
                                    $can('update-request-deliveries') &&
                                        isAllowUpdate(record)
                                "
                                href="#"
                                v-tooltip="$t('requestDelivery.charged')"
                                class="text-info mr-2"
                                @click.prevent="onClickCharged(record)"
                            >
                                <i class="fas fa-hand-holding-usd"></i>
                            </a>
                            <a
                                v-if="$can('update-request-deliveries')"
                                href="#"
                                v-tooltip="$t('edit')"
                                class="text-primary mr-2"
                                @click.prevent="edit(record)"
                            >
                                <i class="fas fa-edit"></i>
                            </a>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
            <a-modal
                v-if="show_form"
                v-model="show_form"
                :title="$t('edit')"
                width="90%"
                :footer="null"
                @cancel="onClose"
                :zIndex="1024"
            >
                <FormEdit :editData="edit_data" @cancel="onClose" />
            </a-modal>
            <a-modal
                v-if="show_form_mark_charged"
                v-model="show_form_mark_charged"
                :title="$t('requestDelivery.charged')"
                :footer="null"
                :zIndex="1024"
            >
                <IsCharged
                    :delivery="delivery"
                    @cancel="onCloseFormEditCharge"
                />
            </a-modal>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import FormEdit from "./components/form-edit.vue";
import IsCharged from "./components/is-charged.vue";
export default {
    name: "requestDeliveryIndex",
    components: {
        FormEdit,
        IsCharged
    },
    data() {
        return {
            loading: false,
            show_form: false,
            show_form_mark_charged: false,
            edit_data: [],
            serviceType: [],
            agencies: [],
            delivery: {}
        };
    },
    created() {
        if (this.$route.query.driverName) {
            this.search = this.$route.query.driverName;
        }
    },
    computed: {
        ...mapState("agency/requestDelivery", [
            "resources",
            "pagination",
            "formModels"
        ]),
        search: {
            get() {
                return this.$store.state.agency.requestDelivery.search;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_SEARCH",
                    newValue
                );
                this.$store.commit("agency/requestDelivery/RESET_CURRENT_PAGE");
            }
        },
        dateRange: {
            get() {
                return this.$store.state.agency.requestDelivery.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("requestDelivery.bookingDate"), sortKey: 'booking_time' },
                { name: this.$t("requestDelivery.transactionDate"), sortKey: 'transaction_date' },
                { name: this.$t("requestDelivery.deliveryStartTime"), sortKey: 'delivery_start_time' },
                { name: this.$t("requestDelivery.finishDate"), sortKey: 'delivery_finish_time' },
                { name: this.$t("requestDelivery.bookingNumber"), sortKey: 'booking_number' },
                { name: this.$t("requestDelivery.transactionNumber"), sortKey: 'delivery_number' },
                { name: this.$t("requestDelivery.shopCode"), sortKey: 'shop_code' },
                { name: this.$t("requestDelivery.shopName"), sortKey: 'shop_name' },
                { name: this.$t("requestDelivery.agency"), sortKey: 'agency_name' },
                { name: this.$t("requestDelivery.pickupFee"), sortKey: 'pickup_fee' },
                { name: this.$t("requestDelivery.pickupDriver"), sortKey: 'driver_pickup' },
                { name: this.$t("requestDelivery.deliveryName"), sortKey: 'driver_name' },
                { name: this.$t("requestDelivery.transferToDriver"), sortKey: 't_driver_name' },
                { name: this.$t("requestDelivery.serviceType"), sortKey: 'service_type_name_kh' },
                { name: this.$t("requestDelivery.numberOfPackage"), sortKey: 'number_of_package' },
                { name: this.$t("requestDelivery.packageType"), sortKey: 'package_type_kh' },
                { name: this.$t("requestDelivery.collectedUsd"), sortKey: 'amount_collected_usd' },
                { name: this.$t("requestDelivery.collectedKhr"), sortKey: 'amount_collected_khr' },
                { name: this.$t("requestDelivery.receiverPhone"), sortKey: 'receiver_phone' },
                { name: this.$t("requestDelivery.receiverLocation"), sortKey: 'receiver_location' },
                { name: this.$t("requestDelivery.status"), sortKey: 'delivery_status' },
                { name: this.$t("requestDelivery.stockAtJD") },
                { name: this.$t("requestDelivery.charged")},
                { name: this.$t("requestDelivery.remark"), sortKey: 'delivery_status_remark' },
                { name: this.$t("requestDelivery.createdBy"), sortKey: 'created_by_name' },
                { name: this.$t("actions") }
            ];
        },
        statusOpt() {
            return [
                { value: "", label: "All" },
                { value: 0, label: "Booking" },
                { value: 1, label: "Request For Delivery" },
                { value: 2, label: "On Delivery" },
                { value: 3, label: "Re-Delivery" },
                { value: 4, label: "Dropped" },
                { value: 5, label: "Transferred" },
                { value: 6, label: "Success" },
                { value: 7, label: "Cancelled" },
                { value: 8, label: "Pending" },
                { value: 9, label: "Return" },
                { value: 10, label: "Confirm Delivery" },
                { value: 11, label: "Confirm Booking" }
            ];
        },
        isAllowUpdate: () => record => {
            return [7, 8, 9].includes(record.delivery_status_id);
        },
        status: {
            get() {
                return this.$store.state.agency.requestDelivery.status;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_STATUS",
                    newValue
                );
            }
        },
        service_type_id: {
            get() {
                return this.$store.state.agency.requestDelivery.service_type_id;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_SERVICE_TYPE",
                    newValue
                );
            }
        },
        agency_id: {
            get() {
                return this.$store.state.agency.requestDelivery.agency_id;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_SERVICE_AGENCY",
                    newValue
                );
            }
        },
        isStockAtJd: {
            get() {
                return this.$store.state.agency.requestDelivery.isStockAtJd;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/requestDelivery/SET_IS_STOCK_AT_JD",
                    newValue
                );
            }
        }
    },
    methods: {
        ...mapActions("agency/requestDelivery", ["getFormViewData"]),
        async getFilterData() {
            await this.getFormViewData({
                index: "serviceType,agency",
                params: {
                    fnName: "serviceType,agency"
                }
            });
            this.serviceType = this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en + "|" + el.service_type_name_kh
            }));
            this.agencies = this.formModels.agency.map(el => ({
                value: el.agency_id,
                label: el.agency_name
            }));
        },
        async fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/requestDelivery/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        edit(record) {
            this.show_form = true;
            this.edit_data = record;
        },
        onClose() {
            this.show_form = false;
            this.fetchData();
        },
        onClickCharged(record) {
            this.delivery = record;
            this.show_form_mark_charged = true;
        },
        onCloseFormEditCharge() {
            this.show_form_mark_charged = false;
            this.fetchData();
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getFilterData();
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/requestDelivery/RESET_STATE");
        next();
    }
};
</script>
